import { defaultLocale } from '@energybox/react-ui-library/dist/types';
import { mapArrayToObject } from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';
import {
  Actions as StreamActions,
  Channel,
  CommandType,
} from '../actions/streamApi';
import { Status } from '../types/status';
import { formatFullDateTime } from '../utils/dates';
import { convertEnergyProSensorIndex } from '../utils/sensor';
import { getMapFromArrayOneToOne } from '@energybox/react-ui-library/dist/utils/util';

export type SubscribedEnergyPro = {
  id: number;
  uuid: string;
  siteId: number;
  organizationId: number;
  timestamp: string;
  deltaTime: number;
  //converted values
  sensors: EnergyProSensorReading[];
  level: string;
};

// All readings converted for KW
export type EnergyProSensorReading = {
  id: number;
  equipmentId: null | number;
  index: number;
  indexString: string;

  phase: number;
  powerActive: number;
  powerReactive: number;
  powerApparent: number;
  powerFactor: number;
  energy: number;
  voltage: number;
  current: number;
};

export type SubscribedEnergyProsById = {
  [energyProId: string]: SubscribedEnergyPro;
};

export type SubscribedEnergyProSensorsReadingBySensorId = {
  [sensorId: number]: EnergyProSensorReading;
};

export type ProposedReadng = {
  equipmentId: number | null;
  index: number;
  phase: number;
  phaseAngle: number;
  reversePolarity: boolean;
};

export type ProposedReadingByIndex = {
  [index: number]: ProposedReadng;
};

export type ProposedReadingByUuid = {
  [uuid: string]: ProposedReadingByIndex;
};

export type SubscribedEnergyPros = {
  byId: SubscribedEnergyProsById;
  subscribedEnergyProSensorsReadingBySensorId: SubscribedEnergyProSensorsReadingBySensorId;
  proposedReadingByUuid: ProposedReadingByUuid;
  status: Status;
};

const initialState = {
  byId: {},
  subscribedEnergyProSensorsReadingBySensorId: {},
  proposedReadingByUuid: {},
  status: Status.INIT,
};

const normalizeEnergyReading = energySensorReadingRaw => ({
  id: energySensorReadingRaw.id,
  uuid: energySensorReadingRaw.uuid,
  siteId: energySensorReadingRaw.siteId,
  organizationId: energySensorReadingRaw.organizationId,
  timestamp: energySensorReadingRaw.timestamp,
  deltaTime: energySensorReadingRaw.deltaTime,
  sensors: (energySensorReadingRaw.sensors || []).map(normalizeSensorValues),
  level: energySensorReadingRaw.level,
});

const normalizeSensorValues = energyProSensorReadings => ({
  id: energyProSensorReadings.id,
  equipmentId: energyProSensorReadings.equipmentId,

  voltage: Number(energyProSensorReadings.voltage.toFixed(1)),
  current: Number(energyProSensorReadings.current.toFixed(1)),
  powerActive: Number((energyProSensorReadings.powerActive / 1000).toFixed(2)),
  powerReactive: Number(
    (energyProSensorReadings.powerReactive / 1000).toFixed(1)
  ),
  powerApparent: Number(
    (energyProSensorReadings.powerApparent / 1000).toFixed(1)
  ),
  powerFactor: energyProSensorReadings.powerFactor.toFixed(2),

  // TODO what conversion is needed for energy?
  energy: Number(energyProSensorReadings.energy.toFixed(1)),

  phase: energyProSensorReadings.phase,
  index: energyProSensorReadings.index,
  indexString: convertEnergyProSensorIndex(energyProSensorReadings.index),
});

const subscribedEnergyPros = (
  state: SubscribedEnergyPros = initialState,
  action: any
) => {
  switch (action.type) {
    case StreamActions.RECEIVED_ENERGY_PRO_READING: {
      const normalizedData = normalizeEnergyReading(action.data);
      const timestamp = formatFullDateTime(
        normalizedData.timestamp,
        action.locale?.fullDateTimeFormat || defaultLocale.fullDateTimeFormat
      );

      return R.pipe(
        R.assocPath(['byId', action.data.id.toString()], normalizedData),
        R.assocPath(
          ['subscribedEnergyProSensorsReadingBySensorId'],
          R.mergeLeft(
            state.subscribedEnergyProSensorsReadingBySensorId,
            normalizedData.sensors.reduce((acc, cur) => {
              acc[cur.id] = { ...cur, timestamp };
              return acc;
            }, {})
          )
        ),
        R.assoc('status', Status.SUCCESS)
      )(state);
    }

    case StreamActions.RECEIVED_PROPOSED_READING: {
      return R.assocPath(
        ['proposedReadingByUuid'],
        R.mergeRight(state.proposedReadingByUuid, {
          [action.data.uuid]: getMapFromArrayOneToOne(
            action.data.readings || [],
            'index'
          ),
        })
      )(state);
    }

    case StreamActions.SEND_MESSAGE: {
      if (action.data.channel === Channel.ENERGY_SENSOR_READING) {
        return {
          ...state,
          status: Status.LOADING,
        };
      }

      if (
        action.data.channel === Channel.PROPOSED_READING &&
        action.data.type === CommandType.UNSUBSCRIBE
      ) {
        return R.assocPath(['proposedReadingByIndex'], {})(state);
      }
      return state;
    }

    default:
      return state;
  }
};

export default subscribedEnergyPros;
