import React from 'react';

import equals from 'ramda/src/equals';
import { EnergySensor } from '@energybox/react-ui-library/dist/types';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';
import {
  SubscribedEnergyProSensorsReadingBySensorId,
  ProposedReadingByIndex,
} from '../../reducers/subscribedEnergyPros';
import { isDefined } from '@energybox/react-ui-library/dist/utils';

const CircuitBreakerPhasorDiagram = ({
  proposedReadingByIndex,
  energySensors,
  selectedSensorIndex,
}: {
  proposedReadingByIndex: ProposedReadingByIndex;
  energySensors?: EnergySensor[];
  selectedSensorIndex?: number | null;
}) => {
  const readingsByEnergySensorId = useSelector<
    ApplicationState,
    SubscribedEnergyProSensorsReadingBySensorId
  >(({ subscribedEnergyPros }) => {
    return subscribedEnergyPros.subscribedEnergyProSensorsReadingBySensorId;
  }, equals);

  // To make sure the selected sensor is always rendered at the end
  // so that it's always shown at the top layer
  const moveToEnd = (
    arr: EnergySensor[] | undefined,
    index?: number | null
  ) => {
    if (arr === undefined || !isDefined(index)) return arr;
    if (index < 0 || index >= arr.length) {
      return arr;
    }
    const res = [...arr];
    const [element] = res.splice(index, 1);
    res.push(element);
    return res;
  };

  const processedEnergySensors = moveToEnd(energySensors, selectedSensorIndex);

  return (
    <svg width="200" height="200">
      <defs>
        <marker
          id="arrowheadRed"
          markerWidth="5"
          markerHeight="3"
          refX="0"
          refY="1.5"
          orient="auto"
        >
          <polygon points="0 0, 5 1.5, 0 3" fill="var(--turquoise-base)" />
        </marker>
        <marker
          id="arrowheadBlue"
          markerWidth="5"
          markerHeight="3"
          refX="0"
          refY="1.5"
          orient="auto"
        >
          <polygon points="0 0, 5 1.5, 0 3" fill="var(--indigo-base)" />
        </marker>
        <marker
          id="arrowheadRedLight"
          markerWidth="5"
          markerHeight="3"
          refX="0"
          refY="1.5"
          orient="auto"
        >
          <polygon
            points="0 0, 5 1.5, 0 3"
            fill="var(--turquoise-basePlus75)"
          />
        </marker>
        <marker
          id="arrowheadBlueLight"
          markerWidth="5"
          markerHeight="3"
          refX="0"
          refY="1.5"
          orient="auto"
        >
          <polygon points="0 0, 5 1.5, 0 3" fill="var(--indigo-basePlus75)" />
        </marker>
      </defs>
      <circle
        cx="100"
        cy="100"
        r="60"
        stroke="var(--ambient-basePlus75)"
        strokeWidth="2"
        fill="transparent"
      />
      {processedEnergySensors &&
        processedEnergySensors.map((energySensor, i, self) => {
          const { id: sensorId } = energySensor;
          const energyReading = readingsByEnergySensorId[energySensor.id];
          if (!energyReading) return null;
          const { index, phase } = energyReading;
          const proposedReadings = proposedReadingByIndex[index];
          if (!proposedReadings) return null;
          const { phaseAngle: currentAngle } = proposedReadings || {
            phaseAngle: undefined,
          };
          const { phaseAngle: voltageAngle } = proposedReadingByIndex[
            199 + phase
          ] || { phaseAngle: undefined };

          const selected =
            !isDefined(selectedSensorIndex) || i === self.length - 1;

          const voltageColor = selected
            ? 'var(--turquoise-base)'
            : 'var(--turquoise-basePlus75)';
          const currentColor = selected
            ? 'var(--indigo-base)'
            : 'var(--indigo-basePlus75)';
          const markerEndRed = selected
            ? 'url(#arrowheadRed)'
            : 'url(#arrowheadRedLight)';
          const markerEndBlue = selected
            ? 'url(#arrowheadBlue)'
            : 'url(#arrowheadBlueLight)';

          return (
            <React.Fragment key={`phasorDiagramVectorsForSensor${sensorId}`}>
              {voltageAngle !== undefined && (
                <>
                  <line
                    x1="100"
                    y1="100"
                    x2="135"
                    y2="100"
                    style={{ stroke: voltageColor, strokeWidth: 2 }}
                    transform={`rotate(-${voltageAngle} 100 100)`}
                    markerEnd={markerEndRed}
                  />
                  <g
                    transform={`rotate(-${voltageAngle} 100 100) translate(50, 0)`}
                  >
                    <text
                      x="100"
                      y="100"
                      textAnchor="middle"
                      dominantBaseline="middle"
                      style={{
                        stroke: voltageColor,
                        fontSize: '0.625rem',
                      }}
                      transform={`rotate(${voltageAngle} 100 100)`}
                    >
                      {phase}
                    </text>
                  </g>
                </>
              )}
              {currentAngle !== undefined && (
                <>
                  <line
                    x1="100"
                    y1="100"
                    x2="135"
                    y2="100"
                    style={{ stroke: currentColor, strokeWidth: 2 }}
                    transform={`rotate(-${currentAngle} 100 100)`}
                    markerEnd={markerEndBlue}
                  />
                  <g
                    transform={`rotate(-${currentAngle} 100 100) translate(48, 0)`}
                  >
                    <text
                      x={100}
                      y={100}
                      textAnchor="middle"
                      style={{ stroke: currentColor, fontSize: '0.625rem' }}
                      transform={`rotate(${currentAngle} 100 100)`}
                    >
                      {phase}
                    </text>
                  </g>
                </>
              )}
            </React.Fragment>
          );
        })}

      <text
        x={50}
        y={195}
        style={{ stroke: 'var(--turquoise-base)', fontSize: '0.75rem' }}
      >
        Voltage
      </text>
      <text
        x={105}
        y={195}
        style={{ stroke: 'var(--indigo-base)', fontSize: '0.75rem' }}
      >
        Current
      </text>
      <text
        textAnchor="middle"
        stroke="var(--base10)"
        dominantBaseline="middle"
        x={170}
        y={100}
        style={{ fontSize: '0.75rem' }}
      >
        0°
      </text>
      <text
        textAnchor="middle"
        stroke="var(--base10)"
        dominantBaseline="middle"
        x={20}
        y={100}
        style={{ fontSize: '0.75rem' }}
      >
        180°
      </text>
      <text
        textAnchor="middle"
        stroke="var(--base10)"
        dominantBaseline="middle"
        x={100}
        y={30}
        style={{ fontSize: '0.75rem' }}
      >
        90°
      </text>
      <text
        textAnchor="middle"
        stroke="var(--base10)"
        dominantBaseline="middle"
        x={100}
        y={175}
        style={{ fontSize: '0.75rem' }}
      >
        270°
      </text>
    </svg>
  );
};

export default CircuitBreakerPhasorDiagram;
