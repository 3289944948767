import {
  Button,
  Card,
  CardContent,
  CardTitle,
  Modal,
  TimeDistance,
} from '@energybox/react-ui-library/dist/components';
import {
  Actuator,
  EnergyPro,
  EnergySensor,
  Equipment,
  ObjectById,
  ResourceType,
} from '@energybox/react-ui-library/dist/types';
import {
  global,
  isDefined,
  mapArrayToObject,
} from '@energybox/react-ui-library/dist/utils';
import equals from 'ramda/src/equals';
import pathOr from 'ramda/src/pathOr';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  patch as patchBreaker,
  reset as resetBreakerField,
  showEnergyProPhasorModal,
} from '../../../actions/circuit_breakers';
import {
  editEnergyDeviceSensorPort,
  mapSensorsInEnergyDeviceToEdit,
  resetEnergyDeviceSensors,
} from '../../../actions/energy_devices';
import {
  convertCtToTitle,
  getEnergyDeviceSensorsOfEnergyPro,
  getPhaseDisplayText,
  isEnergyPro2,
  ProcessedSubscribedEnergySensor,
  processSubscribedEnergyProSensors,
} from '../../../utils/energyPro';
import {
  Actions as EnergyProActions,
  updateEnergyProConfiguration,
} from '../../../actions/energy_pros';
import { useAppLocale } from '../../../hooks/useAppDetails';
import { ApplicationState } from '../../../reducers';
import { EditEnergyDeviceSensorsByEnergyDeviceId } from '../../../reducers/energy_devices';
import { EditEnergyPro, EnergyProsById } from '../../../reducers/energy_pros';
import { EquipmentById } from '../../../reducers/equipment';
import { SubscribedEnergyPro } from '../../../reducers/subscribedEnergyPros';
import { formatFullDateTime } from '../../../utils/dates';
import {
  useEditEnergyPro,
  useHideUpdateEnergyProConfigModal,
  useIsUpdateEnergyProConfigModalShowing,
} from '../../Gateways/GatewayDetailPages/ShowEnergyProPage/ShowEnergyProPage';
import UpdateModal from '../UpdateModal';
import styles from './DistributionPanelPageLiveReadingTable.module.css';
import { ProcessedEnergySensorsById } from '../../../types/energyDevice';
import DeviceOnlineState, {
  DisplayType,
} from '../../DeviceStatus/DeviceOnlineState';
import {
  useActuatorsBySiteId,
  useActuatorsLiveData,
} from '../../../hooks/useControlBoard';
import { getMapFromArrayOneToMany } from '@energybox/react-ui-library/dist/utils/util';
import { renderCircuitStatusText } from '../../Gateways/GatewayDetailPages/ShowControlBoardPage/ShowControlBoardPage';
import { PHASE_LABEL } from '../../Selects/SelectPhase';
import EnergyProPhasorModal from '../../CircuitBreakers/EnergyProPhasorModal/EnergyProPhasorModal';
import {
  subscribeToProposedPhaseReadings,
  unsubscribeToProposedPhaseReadings,
} from '../../../actions/streamApi';
import { formatDecimalValue } from '@energybox/react-ui-library/dist/utils/number';

type ProcessedSensorReading = {
  indexString: string;
  powerActive: number;
  powerReactive: number;
  current: number;
  powerFactor: number;
  voltage: number;
  sensorId: number;
  breakerId: number;
  breakerName: string;
  isMainBreaker: boolean;
  energyDeviceId: number;
  port: number;
  equipment: Equipment;
};

type Props = {
  siteId: number;
  panelId: number;
  energyPros: EnergyPro[];
  activeEnergyPro: EnergyPro | undefined;
  handleActiveEnergyProChange: (value: number) => void;
  type: 'SENSORBUS' | 'EQUIPMENT';
};

const GenericPanelLiveReadingTable: React.FC<Props> = ({
  siteId,
  panelId,
  activeEnergyPro,
  type = 'SENSORBUS',
}) => {
  const locale = useAppLocale();

  const [isBreakerUpdateModalOpen, setIsBreakerUpdateModalOpen] = useState(
    false
  );
  const [
    isEnergyDeviceSensorUpdateModalOpen,
    setIsEnergyDeviceSensorUpdateModalOpen,
  ] = useState(false);
  const activeEnergyProId = isDefined(activeEnergyPro)
    ? String(activeEnergyPro.id)
    : undefined;
  const isUpdateEnergyProConfigModalShowing = useIsUpdateEnergyProConfigModalShowing(
    activeEnergyProId
  );

  const [primedBreakerIdToUpdate, setPrimedBreakerIdToUpdate] = useState<
    number
  >(-1);
  const [
    primedEnergyDeviceSensorToUpdate,
    setPrimedEnergyDeviceSensorToUpdate,
  ] = useState<ProcessedSensorReading | undefined>(undefined);

  const energyProId = activeEnergyPro?.id;
  const isEPro2 = isEnergyPro2(activeEnergyPro);

  //*** useSelect redux ***///
  const editEnergyPro: EditEnergyPro | undefined = useEditEnergyPro(
    activeEnergyProId
  );
  //The actual subscription happens in the parent component: ShowDistributionPanelPage
  const subscribedEnergyPro = useSelector<
    ApplicationState,
    SubscribedEnergyPro | undefined
  >(({ subscribedEnergyPros }) => {
    return pathOr(undefined, [energyProId], subscribedEnergyPros.byId);
  }, equals);

  const energyProsById = useSelector<ApplicationState, EnergyProsById>(
    ({ energyPros }) => {
      return energyPros.energyProsById;
    }
  );

  const editEnergyDeviceSensorsByDeviceId = useSelector<
    ApplicationState,
    EditEnergyDeviceSensorsByEnergyDeviceId
  >(({ energyDevices }) => {
    return energyDevices.editEnergyDeviceSensorsByEnergyDeviceId;
  });

  const equipmentById = useSelector<ApplicationState, EquipmentById>(
    ({ equipment }) => {
      return equipment.equipmentById;
    }
  );

  const primedEquipmentNameForUpdateBreaker = useSelector<
    ApplicationState,
    string | undefined
  >(({ circuitBreakers }) => {
    const equipmentId =
      circuitBreakers.editById[primedBreakerIdToUpdate]?.fields?.equipmentId;

    return equipmentById[equipmentId]?.title;
  });

  const subscribedControlBoardOutputStates = useSelector(
    ({ subscribedControlBoardOutputStates }: ApplicationState) =>
      subscribedControlBoardOutputStates
  );
  //*** useSelect redux end ***///

  ///*** useCallback ***///
  const dispatch = useDispatch();
  const onUpdateEnergyProConfig = useCallback(() => {
    if (activeEnergyPro) {
      dispatch(updateEnergyProConfiguration(String(activeEnergyPro.id)));
    }
  }, [dispatch, activeEnergyPro]);

  const showPhasorModal = useCallback(
    (id: number) => {
      dispatch(showEnergyProPhasorModal(id));
    },
    [dispatch]
  );

  const hideUpdateEnergyProConfigModal = useHideUpdateEnergyProConfigModal(
    dispatch,
    activeEnergyProId
  );

  const mapEnergyDeviceSensorsInRedux = useCallback(
    (sensors: EnergySensor[], energyDeviceId: number) => {
      dispatch(mapSensorsInEnergyDeviceToEdit(sensors, energyDeviceId));
    },
    [dispatch]
  );

  const resetBreakerEdit = useCallback(
    (breakerId: number) => {
      dispatch(resetBreakerField(breakerId));
    },
    [dispatch]
  );

  const onPatchBreaker = useCallback(
    (breakerId: number) => {
      dispatch(patchBreaker(panelId, breakerId));
    },
    [dispatch]
  );

  const resetEnergyDeviceSensor = useCallback(
    (energyDeviceId: number, isEnergyPro: boolean) => {
      dispatch(resetEnergyDeviceSensors(energyDeviceId, isEnergyPro));
    },
    [dispatch]
  );

  const onPatchEnergyDeviceSensor = useCallback(
    (energyDeviceId: number | string, port: number | string) => {
      dispatch(editEnergyDeviceSensorPort(energyDeviceId, port));
    },
    [dispatch]
  );
  ///*** useCallback end ***///

  ///*** useEffect ***///
  useEffect(() => {
    if (!activeEnergyPro) return;
    dispatch(
      subscribeToProposedPhaseReadings(
        activeEnergyPro.vendor,
        activeEnergyPro.uuid,
        activeEnergyPro.id
      )
    );

    return () => {
      dispatch(
        unsubscribeToProposedPhaseReadings(
          activeEnergyPro.vendor,
          activeEnergyPro.uuid,
          activeEnergyPro.id
        )
      );
    };
  }, [activeEnergyPro]);
  ///*** useEffect ***///

  ///*** useMemo values ***///
  const energySensorsById: ProcessedEnergySensorsById = useMemo(() => {
    const energyDeviceSensors = getEnergyDeviceSensorsOfEnergyPro(
      activeEnergyPro,
      {
        mapEnergyDeviceSensorsReduxAction: mapEnergyDeviceSensorsInRedux,
      }
    );

    return mapArrayToObject(energyDeviceSensors || []);
  }, [activeEnergyPro, mapEnergyDeviceSensorsInRedux]);

  const actuators = useActuatorsBySiteId(siteId);
  const showSCColumn = actuators && actuators.length > 0;

  const actuatorsByEquipmentId: ObjectById<Actuator[]> = useMemo(() => {
    return getMapFromArrayOneToMany(actuators || [], 'equipmentId');
  }, [actuators]);

  // Subscribe to actuators live data
  useActuatorsLiveData(actuators);

  const processedData = useMemo(() => {
    return processSubscribedEnergyProSensors(
      subscribedEnergyPro,
      energySensorsById,
      isEPro2
    );
  }, [subscribedEnergyPro, energySensorsById]);

  const insertByPhase = (
    arr: ProcessedSubscribedEnergySensor[],
    curr: ProcessedSubscribedEnergySensor
  ) => {
    for (let i = 0; i < arr.length; i++) {
      if (Number(arr[i].phase) > Number(curr.phase)) {
        arr.splice(i, 0, curr);
        return;
      }
    }
    arr.push(curr);
  };

  const processedDataByEquipmentId = processedData.reduce(
    (acc, curr) => {
      if (curr.isMainBreaker) {
        if (!acc[0]) acc[0] = [];
        acc[0].push(curr);
      } else {
        const key = curr?.equipmentId || -1;
        if (!acc[key]) {
          acc[key] = [];
        }
        if (key === -1) acc[key].push(curr);
        else insertByPhase(acc[key], curr);
      }
      return acc;
    },
    {} as {
      [equipmentId: number]: ProcessedSubscribedEnergySensor[];
    }
  );

  const sortedEquipments = Object.keys(processedDataByEquipmentId)
    .map(key => ({
      id: key,
      name: processedDataByEquipmentId[key]?.[0]?.equipmentTitle,
      breakerIds: processedDataByEquipmentId[key]?.map(
        (s: ProcessedSubscribedEnergySensor) => s.breakerId
      ),
    }))
    .sort((a, b) => {
      if (a.id === '0' || b.id === '-1') return -1;
      if (a.id === '-1' || b.id === '0') return 1;
      return ('' + a.name).localeCompare(b.name);
    });

  ///*** useMemo Values end ***///

  ///*** Local Functions ***///

  const onCancelUpdateBreakerModal = () => {
    resetBreakerEdit(primedBreakerIdToUpdate);
    setIsBreakerUpdateModalOpen(false);
    setPrimedBreakerIdToUpdate(-1);
  };

  const onConfirmUpdateBreakerModal = () => {
    onPatchBreaker(primedBreakerIdToUpdate);
    setIsBreakerUpdateModalOpen(false);
    setPrimedBreakerIdToUpdate(-1);
  };

  const onCancelUpdateEnergyDeviceSensorModal = () => {
    const { energyDeviceId } = primedEnergyDeviceSensorToUpdate || {};
    if (energyDeviceId) {
      const isEnergyPro =
        energyProsById[energyDeviceId]?.resourceType === ResourceType.ENERGYPRO;

      resetEnergyDeviceSensor(energyDeviceId, isEnergyPro);
      setIsEnergyDeviceSensorUpdateModalOpen(false);
      setPrimedEnergyDeviceSensorToUpdate(undefined);
    }
  };

  const onConfirmUpdateEnergyDeviceSensorModal = () => {
    const { energyDeviceId, port } = primedEnergyDeviceSensorToUpdate || {};
    if (energyDeviceId && port) {
      onPatchEnergyDeviceSensor(energyDeviceId, port);
      setIsEnergyDeviceSensorUpdateModalOpen(false);
      setPrimedEnergyDeviceSensorToUpdate(undefined);
    }
  };

  const onConfirmUpdateEnergyProConfigModal = () => {
    onUpdateEnergyProConfig();
  };

  const energyProConfigModalText = (
    <span>
      Are you sure you want to update the configuration of{' '}
      {activeEnergyPro ? (
        <span className={styles.bold}>{activeEnergyPro.title}</span>
      ) : (
        'this Energy Pro'
      )}
      ?
    </span>
  );

  const breakerModalText = (
    <span>
      Are you sure you want to add{' '}
      {primedEquipmentNameForUpdateBreaker ? (
        <span className={styles.bold}>
          {primedEquipmentNameForUpdateBreaker}
        </span>
      ) : (
        'this equipment'
      )}{' '}
      to this circuit breaker?
    </span>
  );

  const energyDeviceSensorTitle = primedEnergyDeviceSensorToUpdate?.indexString;
  const energyDeviceSensorModalText = (
    <span>
      Are you sure you want to update{' '}
      {energyDeviceSensorTitle ? (
        <span className={styles.bold}>{energyDeviceSensorTitle}</span>
      ) : (
        'this Energy Device sensor'
      )}
      ?
    </span>
  );

  const renderUpdateModal = (
    onCancelModal: () => void,
    onConfirmUpdate: () => void,
    modalText: React.ReactNode
  ) => {
    const actions = (
      <span>
        <Button variant="text" onClick={onCancelModal}>
          Cancel
        </Button>
        <Button onClick={onConfirmUpdate}>Confirm</Button>
      </span>
    );

    return (
      <Modal actions={actions}>
        <div className={styles.updateBreakerModalContent}>{modalText}</div>
      </Modal>
    );
  };
  ///*** Local Functions end ***///

  ///*** Table Columns ***///
  const columns = [
    (type === 'EQUIPMENT' && {
      name: 'Equipment',
    }) ||
      undefined,
    {
      name: 'Index',
      cellContent: (s: ProcessedSubscribedEnergySensor) => (
        <span>{s.indexString}</span>
      ),
    },
    {
      name: 'Breakers Name',
      cellContent: (s: ProcessedSubscribedEnergySensor) => (
        <span className={styles.breakerName}>
          {s.breakerName || global.NOT_AVAILABLE}
        </span>
      ),
    },
    (type === 'SENSORBUS' && {
      name: 'Equipment',
      cellContent: (s: ProcessedSubscribedEnergySensor) =>
        s.equipmentTitle || global.NOT_AVAILABLE,
    }) ||
      undefined,
    {
      name: 'CT Type',
      cellContent: (s: ProcessedSubscribedEnergySensor) => {
        const ctTypeValue = pathOr(
          undefined,
          [s.energyDeviceId, s.port, 'fields', 'ct'],
          editEnergyDeviceSensorsByDeviceId
        );
        return convertCtToTitle(ctTypeValue) || global.NOT_AVAILABLE;
      },
    },
    {
      name: 'Phase',
      cellContent: (sensor: ProcessedSubscribedEnergySensor) => {
        const configuredPhase = pathOr(
          undefined,
          [sensor.energyDeviceId, sensor.port, 'fields', 'phase'],
          editEnergyDeviceSensorsByDeviceId
        );
        if (!isEPro2)
          return PHASE_LABEL[configuredPhase] || global.NOT_AVAILABLE;

        const { phaseToShow, phaseSource } = getPhaseDisplayText(
          configuredPhase,
          sensor.phase
        );

        const text = phaseToShow.padEnd(10) + phaseSource;
        const formattedText = text.replace(/ /g, '\u00A0');
        return <>{formattedText}</>;
      },
    },
    {
      name: 'CT Polarity',
      cellContent: (s: ProcessedSubscribedEnergySensor) => {
        const reversePolarityValue: boolean | undefined = pathOr(
          undefined,
          [s.energyDeviceId, s.port, 'fields', 'reversePolarity'],
          editEnergyDeviceSensorsByDeviceId
        );

        if (reversePolarityValue === undefined) {
          return global.NOT_AVAILABLE;
        }

        return reversePolarityValue ? 'Reverse' : 'Normal';
      },
    },
    {
      name: 'Current (A)',
      cellContent: (s: ProcessedSubscribedEnergySensor) => (
        <span className={styles.readingNumber}>
          {formatDecimalValue(s.current, 1)}
        </span>
      ),
    },
    {
      name: 'Voltage (V)',
      cellContent: (s: ProcessedSubscribedEnergySensor) => (
        <span className={styles.readingNumber}>
          {formatDecimalValue(s.voltage, 1)}
        </span>
      ),
    },
    {
      name: 'Power Factor',
      cellContent: (s: ProcessedSubscribedEnergySensor) => (
        <span className={styles.readingNumber}>
          {formatDecimalValue(Number(s.powerFactor), 2)}
        </span>
      ),
    },
    {
      name: 'Active Power',
      cellContent: (s: ProcessedSubscribedEnergySensor) => (
        <span className={styles.readingNumber}>
          {formatDecimalValue(s.powerActive, 2)}
        </span>
      ),
    },
    (showSCColumn && {
      name: 'SiteController Relay Port/Circuit Status',
      cellContent: (s: ProcessedSubscribedEnergySensor) => {
        const actuators =
          s.equipmentId && actuatorsByEquipmentId[s.equipmentId]
            ? actuatorsByEquipmentId[s.equipmentId].sort(
                (a, b) => a.port - b.port
              )
            : undefined;

        return (
          <>
            {actuators
              ? actuators.map(
                  ({ port, portType, id, controlBoardId }, index) => {
                    const subscribedActuatorStates =
                      subscribedControlBoardOutputStates[controlBoardId]?.state;
                    return (
                      <span className={styles.circuitStatus}>
                        {port} /{' '}
                        {renderCircuitStatusText(
                          port,
                          portType,
                          subscribedActuatorStates
                        )}
                      </span>
                    );
                  }
                )
              : global.NOT_AVAILABLE}
          </>
        );
      },
    }) ||
      undefined,
  ].filter(c => isDefined(c));
  ///*** Table Columns end ***///

  let rowIndex = -1;
  return (
    <>
      <Card className={styles.cardContainer}>
        <CardContent className={styles.cardContent}>
          <CardTitle className={styles.cardTitle}>
            <div>
              <div className={styles.headerLeftAlign}>
                <span className={styles.headerTitle}>
                  EnergyPro Live Readings
                </span>
              </div>
              <div className={styles.energyProTitleContainer}>
                {activeEnergyPro ? (
                  <>
                    <DeviceOnlineState
                      displayType={DisplayType.STATUS_ONLY_WITHOUT_TEXT}
                      devices={[
                        {
                          id: activeEnergyPro.id,
                          uuid: activeEnergyPro.uuid,
                          vendor: activeEnergyPro.vendor,
                        },
                      ]}
                    />
                    <span className={styles.energyProTitle}>
                      {activeEnergyPro.title}
                    </span>
                  </>
                ) : (
                  <>{global.NOT_AVAILABLE}</>
                )}
              </div>
              <div className={styles.headerRightAlign}>
                {subscribedEnergyPro ? (
                  <span
                    title={formatFullDateTime(
                      subscribedEnergyPro.timestamp,
                      locale.fullDateTimeFormat
                    )}
                  >
                    Last Reading:&nbsp;
                    <TimeDistance timestamp={subscribedEnergyPro.timestamp} />
                  </span>
                ) : (
                  global.NOT_AVAILABLE
                )}
              </div>
            </div>
          </CardTitle>

          <div
            className={
              showSCColumn
                ? type === 'EQUIPMENT'
                  ? styles.liveReadingTable
                  : styles.liveReadingTableByIndex
                : type === 'EQUIPMENT'
                ? styles.liveReadingTableWithoutSC
                : styles.liveReadingTableByIndexWithoutSC
            }
          >
            {columns.map(
              (c, i) =>
                (c && (
                  <div
                    className={styles.headerStyle}
                    style={
                      (i >= 6 &&
                        i <= 10 && {
                          justifyContent: 'right',
                          textAlign: 'right',
                        }) ||
                      undefined
                    }
                  >
                    {c.name}
                  </div>
                )) ||
                null
            )}
          </div>

          <div className={styles.scrollContainer}>
            <div
              className={
                showSCColumn
                  ? type === 'EQUIPMENT'
                    ? styles.liveReadingTable
                    : styles.liveReadingTableByIndex
                  : type === 'EQUIPMENT'
                  ? styles.liveReadingTableWithoutSC
                  : styles.liveReadingTableByIndexWithoutSC
              }
            >
              {type === 'SENSORBUS' &&
                processedData.map(
                  (
                    sensorReadings: ProcessedSubscribedEnergySensor,
                    index: number
                  ) => {
                    const highlighted = index % 2 === 0;
                    index += 1;
                    return (
                      <>
                        {columns.map(
                          c =>
                            c && (
                              <div
                                className={
                                  highlighted
                                    ? styles.highlightedCellStyle
                                    : styles.cellStyle
                                }
                              >
                                {c.cellContent?.(sensorReadings)}
                              </div>
                            )
                        )}
                      </>
                    );
                  }
                )}

              {type === 'EQUIPMENT' &&
                sortedEquipments.map((equipment, i) => {
                  const sensorReadings =
                    processedDataByEquipmentId[Number(equipment.id)];
                  return (
                    <>
                      {i !== 0 &&
                        new Array(columns.length)
                          .fill(0)
                          .map(_ => <div className={styles.separator} />)}
                      <div
                        className={styles.cellStyle}
                        style={{
                          gridRow: 'span ' + sensorReadings.length,
                          position: 'relative',
                        }}
                      >
                        <span
                          className={styles.equipmentTitle}
                          onClick={() => showPhasorModal(Number(equipment.id))}
                        >
                          {equipment.name}
                        </span>
                        {activeEnergyPro && (
                          <EnergyProPhasorModal
                            equipmentName={equipment.name}
                            equipmentId={Number(equipment.id)}
                            breakerIds={equipment.breakerIds}
                            energyPro={activeEnergyPro}
                            indexStrings={sensorReadings.map(
                              s => s.indexString
                            )}
                          />
                        )}
                      </div>
                      {sensorReadings.map(
                        (p: ProcessedSubscribedEnergySensor) => {
                          rowIndex += 1;
                          const highlighted = rowIndex % 2 === 0;
                          return (
                            <>
                              {columns.map(
                                c =>
                                  !!c &&
                                  c.name !== 'Equipment' && (
                                    <div
                                      className={
                                        highlighted
                                          ? styles.highlightedCellStyle
                                          : styles.cellStyle
                                      }
                                    >
                                      {c.cellContent?.(p)}
                                    </div>
                                  )
                              )}
                            </>
                          );
                        }
                      )}
                    </>
                  );
                })}
            </div>
          </div>
        </CardContent>
      </Card>

      {isBreakerUpdateModalOpen &&
        renderUpdateModal(
          onCancelUpdateBreakerModal,
          onConfirmUpdateBreakerModal,
          breakerModalText
        )}
      {isEnergyDeviceSensorUpdateModalOpen &&
        renderUpdateModal(
          onCancelUpdateEnergyDeviceSensorModal,
          onConfirmUpdateEnergyDeviceSensorModal,
          energyDeviceSensorModalText
        )}
      {isUpdateEnergyProConfigModalShowing && (
        <UpdateModal
          onCancelModal={hideUpdateEnergyProConfigModal}
          onConfirmUpdate={onConfirmUpdateEnergyProConfigModal}
          modalText={energyProConfigModalText}
          apiError={editEnergyPro?.apiError}
          apiErrorAction={
            EnergyProActions.UPDATE_ENERGY_PRO_CONFIGURATION_ERROR
          }
        />
      )}
    </>
  );
};

export default GenericPanelLiveReadingTable;
