import { EnergySensor } from '@energybox/react-ui-library/dist/types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';
import {
  SubscribedEnergyProSensorsReadingBySensorId,
  ProposedReadingByIndex,
} from '../../reducers/subscribedEnergyPros';
import LiveReadingTable, {
  RowCellFormatter,
} from '../LiveReadingTable/LiveReadingTable';

import { global } from '@energybox/react-ui-library/dist/utils';

import equals from 'ramda/src/equals';

const CircuitBreakerTableHeaders = [
  'Pole',
  'Phase',
  'Phase Proposed',
  'Voltage',
  'Angle',
  'Current',
  'Angle',
];

const CircuitBreakerReadings = ({
  proposedReadingByIndex,
  energySensors,
  selectedSensorIndex,
  setSelectedSensorIndex,
}: {
  proposedReadingByIndex: ProposedReadingByIndex;
  energySensors?: EnergySensor[];
  selectedSensorIndex?: number | null;
  setSelectedSensorIndex?: (id: number | null) => void;
}) => {
  const readingsByEnergySensorId = useSelector<
    ApplicationState,
    SubscribedEnergyProSensorsReadingBySensorId
  >(({ subscribedEnergyPros }) => {
    return subscribedEnergyPros.subscribedEnergyProSensorsReadingBySensorId;
  }, equals);

  const rowData: Array<RowCellFormatter[]> = useMemo(() => {
    if (!energySensors) return [] as Array<RowCellFormatter[]>;
    const rows = energySensors
      .sort((sensorA, sensorB) => {
        return sensorA.breakerPole - sensorB.breakerPole;
      })
      .map(energySensor => {
        const energyReadings = readingsByEnergySensorId[energySensor.id];
        if (!energyReadings) return [];
        const proposedReadings = proposedReadingByIndex[energyReadings.index];
        if (!proposedReadings) return [];

        const voltageProposedReading =
          proposedReadingByIndex[199 + energyReadings.phase];

        const rowCells: RowCellFormatter[] = [
          {
            value: energySensor.breakerPole,
          },
          {
            value: energyReadings.phase,
          },
          {
            value:
              proposedReadings.phase !== undefined
                ? proposedReadings.phase
                : global.NOT_AVAILABLE,
          },
          {
            value: energyReadings.voltage,
            unit: 'V',
            decimal: 1,
            align: 'right',
          },
          {
            // voltage angle
            value: voltageProposedReading?.phaseAngle,
            unit: '°',
            align: 'right',
          },
          {
            value: energyReadings.current,
            unit: 'A',
            decimal: 1,
            align: 'right',
          },
          {
            // current angle
            value: proposedReadings?.phaseAngle,
            unit: '°',
            align: 'right',
          },
        ];
        return rowCells;
      });

    return rows;
  }, [energySensors, readingsByEnergySensorId, proposedReadingByIndex]);

  return (
    <LiveReadingTable
      selectedRowIndex={selectedSensorIndex}
      onSelectRow={
        setSelectedSensorIndex &&
        ((index: number | null) => setSelectedSensorIndex(index))
      }
      headers={CircuitBreakerTableHeaders}
      rows={rowData}
    />
  );
};

export default CircuitBreakerReadings;
