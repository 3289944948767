import React from 'react';
import { Checkbox, Tooltip } from '@energybox/react-ui-library/dist/components';
import {
  GenericFunction,
  InspectionComponentName,
  InspectionComponentToName,
  InspectionDataFieldsByKey,
  SensorType,
  SensorTypesText,
} from '@energybox/react-ui-library/dist/types';
import { ErrorIcon, WarningIcon } from '@energybox/react-ui-library/dist/icons';
import {
  classNames,
  shortenString,
} from '@energybox/react-ui-library/dist/utils';
import {
  getTitle,
  getId,
  doesItemContainErrorOrWarning,
  checkFieldsErrorsAndWarnings,
  getSensorTypes,
} from '@energybox/react-ui-library/dist/utils/inspection';

import styles from './HierarchyTree.module.css';
import { InspectionReportKey } from '../SiteInspectionEnums';

type Props = {
  data?: InspectionDataFieldsByKey;
  onNodeClick?: GenericFunction;
};

type Node = {
  title?: string;
  refId?: string;
  hasError?: boolean;
  hasWarning?: boolean;
  nodes?: Node[];
};

type TreeNodeProps = Node & {
  layer?: number;
  onNodeClick?: GenericFunction;
};

const getSensorTree = (sensors, options = { isUnpaired: false }) => {
  const validSensors = sensors?.filter(sensor => sensor);
  if (!validSensors || !validSensors.length) {
    return [];
  }

  const sensorTypeCounts = validSensors.reduce((acc, sensor) => {
    const sensorTypes = getSensorTypes(sensor);
    sensorTypes?.forEach(type => {
      if (!acc[type]) {
        acc[type] = {
          count: 0,
          hasError: false,
          hasWarning: false,
        };
      }
      acc[type].count++;
      const { hasError, hasWarning } = checkFieldsErrorsAndWarnings(sensor);
      acc[type].hasError = acc[type].hasError || hasError;
      acc[type].hasWarning = acc[type].hasWarning || hasWarning;
    });
    return acc;
  }, {});

  const nodes = Object.keys(sensorTypeCounts).map(type => ({
    title: `${
      type === 'AMBIENT'
        ? 'Ambient'
        : type === SensorType.BINARY
        ? 'Door Access'
        : SensorTypesText[type]
    } (${sensorTypeCounts[type].count})`,
    hasError: sensorTypeCounts[type].hasError,
    hasWarning: sensorTypeCounts[type].hasWarning,
  })) as Node[];

  return [
    {
      title: `${
        options.isUnpaired
          ? InspectionComponentName.SENSORS_NEARBY
          : InspectionComponentName.SENSORS
      } (${validSensors.length})`,
      refId: options.isUnpaired ? 'sensors-unpaired' : undefined,
      hasError: nodes.some(node => node.hasError),
      hasWarning: nodes.some(node => node.hasWarning),
      nodes,
    },
  ] as Node[];
};

const getSpecificSensorsOrActuatorsTree = (title, items) =>
  items?.length
    ? ([
        {
          title: `${title} (${items.length})`,
          hasError: items.some(item =>
            doesItemContainErrorOrWarning(item, 'error')
          ),
          hasWarning: items.some(item =>
            doesItemContainErrorOrWarning(item, 'warning')
          ),
        },
      ] as Node[])
    : [];

const getEnergyPro2Tree = (title, items) => {
  if (!items?.length) return [];

  const sensorCount = items.filter(
    i => i.sensor_status.field === 'Active' || !!i.equipment_id.field
  ).length;

  return [
    {
      title: `${title} (${sensorCount})`,
      hasError: items.some(item =>
        doesItemContainErrorOrWarning(item, 'error')
      ),
      hasWarning: items.some(item =>
        doesItemContainErrorOrWarning(item, 'warning')
      ),
    },
  ] as Node[];
};

const transformDataToTree = data => {
  const tree = [] as Node[];
  Object.keys(data).forEach(key => {
    if (key === 'unpaired_sensors') {
      const sensorTree = getSensorTree(data[key].sensors, { isUnpaired: true });
      sensorTree[0] && tree.push(sensorTree[0]);
      return;
    }
    const keyForName =
      key === 'super_edge_controllers'
        ? 'edge_controllers'
        : key === 'superhub_sensors'
        ? 'sensors'
        : key;

    data[key].forEach(component => {
      tree.push({
        title:
          InspectionComponentToName[keyForName] +
          (getTitle(component) ? ` (${getTitle(component)})` : ''),
        refId: `${key}-${getId(component)}`,
        ...checkFieldsErrorsAndWarnings(component),
        nodes: ['hubs', 'superhub_sensors'].includes(key)
          ? getSensorTree(component.sensors)
          : key === 'energy_pros'
          ? getSpecificSensorsOrActuatorsTree(
              'EnergyPro CT',
              component.ct_sensors
            )
          : key === 'energy_pro2s'
          ? getEnergyPro2Tree('EnergyPro II CT', component.ct_sensors)
          : key === InspectionReportKey.VENSTAR_THERMOSTATS
          ? getSpecificSensorsOrActuatorsTree(
              InspectionComponentName.EXTERNAL_SENSORS,
              component.sensor_list
            )
          : key === InspectionReportKey.EB_THERMOSTATS
          ? getSpecificSensorsOrActuatorsTree(
              InspectionComponentName.EXTERNAL_WIRELESS_SENSORS,
              component.sensor_list
            )
          : key === InspectionReportKey.SITE_CONTROLLERS
          ? getSpecificSensorsOrActuatorsTree(
              InspectionComponentName.ACTUATORS,
              component.actuators
            )
          : [],
      });
    });
  });
  return tree;
};

const renderTreeNodes = (nodes, onNodeClick, layer = 0) => {
  const subLayer = layer + 1;
  return nodes.map(node => {
    return (
      <div className={styles.treeNodeContainer}>
        <TreeNode {...node} layer={layer} onNodeClick={onNodeClick} />
        {!!node.nodes && (
          <div className={styles.subTree}>
            {renderTreeNodes(node.nodes, onNodeClick, subLayer)}
          </div>
        )}
      </div>
    );
  });
};

const HierarchyTree: React.FC<Props> = ({ data, onNodeClick }) => {
  if (!data) {
    return null;
  }
  return renderTreeNodes(transformDataToTree(data), onNodeClick);
};

const TreeNode: React.FC<TreeNodeProps> = ({
  title = '',
  refId,
  hasError,
  hasWarning,
  layer,
  onNodeClick,
}) => {
  const maxStringLength = 35 - 3 * (layer || 0);
  const titleElement = (
    <span
      className={classNames(
        styles.nodeTitle,
        refId ? styles.clickableNodeTitle : ''
      )}
      onClick={() => onNodeClick?.(refId)}
    >
      {shortenString(title, maxStringLength)}
    </span>
  );
  return (
    <div className={styles.treeNode}>
      <div className={styles.nodeVerticalLine} />
      <div className={styles.nodeHorizontalLine} />
      <Checkbox
        className={styles.nodeCheckbox}
        onChange={() => {}}
        checked={false}
        disabled
      >
        <div className={styles.checkboxFill} />
        {title.length <= maxStringLength && titleElement}
        {title.length > maxStringLength && (
          <Tooltip
            content={title}
            arrowDirection="top"
            extraClassNames={[styles.nodeTitle]}
            tooltipTextClassName={styles.nodeTitleTooltip}
            disableAutoAdjust={true}
          >
            {titleElement}
          </Tooltip>
        )}
        {hasError && <ErrorIcon width="16" height="16" />}
        {!hasError && hasWarning && <WarningIcon width="16" height="16" />}
      </Checkbox>
    </div>
  );
};

export default HierarchyTree;
