import {
  Checkbox,
  InputField,
  Label,
} from '@energybox/react-ui-library/dist/components';
import {
  EnergyPro,
  MainBreaker,
  mapMainBreakerPoleToPhase,
} from '@energybox/react-ui-library/dist/types';

import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Actions as MainBreakerActions,
  setEditMainBreaker,
  updateField,
} from '../../actions/main_breakers';
import {
  subscribeToProposedPhaseReadings,
  unsubscribeToProposedPhaseReadings,
} from '../../actions/streamApi';

import SelectBreakerRating from '../../containers/Selects/SelectBreakerRating';
import { ApplicationState } from '../../reducers';
import { EditMainBreaker } from '../../reducers/main_breakers';
import { renderAPIerror } from '../../utils/apiErrorFeedback';
import ModalFormContent from '../ModalFormContent';
import equals from 'ramda/src/equals';

import styles from './EditMainBreakerForm.module.css';
import { ProposedReadingByUuid } from '../../reducers/subscribedEnergyPros';
import CircuitBreakerReadings from '../CircuitBreaker/CircuitBreakerReadings';
import CircuitBreakerPhasorDiagram from '../CircuitBreaker/CircuitBreakerPhasorDiagram';
import PowerReadings from '../CircuitBreaker/PowerReadings';

interface Props {
  mainBreaker: MainBreaker;
  energyPro?: EnergyPro;
}

const EditMainBreakerForm = ({ mainBreaker, energyPro }: Props) => {
  // *********** Actions ************* //

  const dispatch = useDispatch();
  useEffect(() => {
    if (!energyPro) return;
    dispatch(
      subscribeToProposedPhaseReadings(
        energyPro.vendor,
        energyPro.uuid,
        energyPro.id
      )
    );

    return () => {
      dispatch(
        unsubscribeToProposedPhaseReadings(
          energyPro.vendor,
          energyPro.uuid,
          energyPro.id
        )
      );
    };
  }, [energyPro]);

  const setEditMainBreakerFields = useCallback(() => {
    dispatch(setEditMainBreaker(mainBreaker));
  }, [dispatch, mainBreaker]);

  const onChange = useCallback(
    (field: string, value: any) => {
      dispatch(updateField(mainBreaker.id, field, value));
    },
    [dispatch, mainBreaker.id]
  );

  // ************ UseEffect ************ //
  useEffect(() => {
    setEditMainBreakerFields();
  }, [setEditMainBreakerFields]);

  // *********** UseSelector ************ //
  const editMainBreaker: EditMainBreaker | undefined = useSelector(
    ({ mainBreakers }: ApplicationState) => {
      return mainBreakers.editById[mainBreaker.id];
    }
  );

  const proposedReadingByUuid = useSelector<
    ApplicationState,
    ProposedReadingByUuid
  >(({ subscribedEnergyPros }) => {
    return subscribedEnergyPros.proposedReadingByUuid;
  }, equals);

  const proposedReadingByIndex =
    (energyPro && proposedReadingByUuid[energyPro.uuid]) || {};

  const { fields, formErrors, formErrorsVisible, apiError } =
    editMainBreaker || {};
  const { title, description, siteTotal, rating } = fields || {};

  return (
    <div>
      <ModalFormContent>
        <div>
          <Label required htmlFor="title">
            Title
          </Label>
        </div>
        <div>
          <InputField
            id="title"
            type="text"
            name="title"
            value={title || ''}
            onChange={e => onChange('title', e.currentTarget.value)}
            error={formErrorsVisible && !!formErrors.title}
          />
        </div>

        <div>
          <Label htmlFor="description">Description</Label>
        </div>
        <div>
          <InputField
            id="description"
            type="text"
            name="description"
            value={description || ''}
            onChange={e => onChange('description', e.currentTarget.value)}
          />
        </div>

        <div>
          <Label required htmlFor="rating">
            Breaker rating (Amperes)
          </Label>
        </div>
        <div>
          <SelectBreakerRating
            value={rating}
            error={formErrorsVisible && !!formErrors.rating}
            onSelect={selectedRating => onChange('rating', selectedRating)}
          />
        </div>

        <div>
          <Label required>Site total</Label>
        </div>
        <div className={styles.alignCheckboxVerticalCenter}>
          <Checkbox
            checked={siteTotal || false}
            onChange={() => onChange('siteTotal', !siteTotal)}
          />
        </div>

        <div>
          <Label>Phase</Label>
        </div>
        <div className={styles.grayText}>
          {mapMainBreakerPoleToPhase[mainBreaker.poles]}
        </div>
      </ModalFormContent>

      {renderAPIerror(apiError, MainBreakerActions.PATCH_MAIN_BREAKER_ERROR)}

      <ModalFormContent>
        <Label>* Mandatory fields</Label>
      </ModalFormContent>
      <CircuitBreakerReadings
        proposedReadingByIndex={proposedReadingByIndex}
        energySensors={energyPro?.sensors}
      />

      <div className={styles.phasorDiagramContainer}>
        <CircuitBreakerPhasorDiagram
          proposedReadingByIndex={proposedReadingByIndex}
          energySensors={energyPro?.sensors}
        />
        <PowerReadings energySensors={energyPro?.sensors} />
      </div>
    </div>
  );
};

export default EditMainBreakerForm;
